.localitiesBox {
  h3 {
    font-family: $font-sourceSansPro;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.875em;
  }
  
  .localitiesBoxContent {
    max-height: 14.8em;
    overflow: auto;
    margin-top: 0.8em;
    display: flex;
    flex-wrap: wrap;
    div {
      margin-right: 0.5em;
      margin-bottom: 0.5em;
      background-color: $lighterSaphir;
      padding: 0 0.5em 0.1em 0.5em;
      color: $saphir;
      border-radius: 0.3em;
      span {
        margin: 0;
        font-size: 0.875em;
        font-weight: 600;
      }
    }
  }
}
