.wrapperCounts {
  .comingSoon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.5em 2.5em 2.8em 2.4em;
    border-radius: 0.5em;
    background-color: $lighterGrey;
    height: 17em;

    p {
      font-family: $font-sourceSansPro;
      font-size: 1.5em;
      color: $black;
    }
  }
  .contentCounts {
    padding: 2.5em;

    h2 {
      font-size: 2em;
      font-family: $font-sourceSansPro;
      font-weight: 700;
      color: $black;
    }
  }
  .wrapperCard {
    display: grid;
    grid-auto-rows: 1fr;
    row-gap: 2em;
    column-gap: 2em;
    padding: 0 2.5em;
    margin-bottom: 2em;
  }
}
