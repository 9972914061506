.inputSearchListWrapper {
    width: 100%;
    background-color: $greyAutocomplete;
    margin-top: 1.5em;
    margin-bottom: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0.75em;
    border-radius: 0.5em;

    div[data-testid="gsl.uilib.InputGroup.container"] {
      box-shadow: 0px 2px 8px 0px #00000029;
      border: none;
    }

    button[data-testid="gsl.uilib.InputGroup.button"] {
      background-color: $white !important;
      border-color: $black !important;
      color: $black !important;

      &:before {
        background-color: $white !important;
        content: unset;
      }
    }
  }