.tableResult.tableResultProposal {

  .rowAlign {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  > div {
    padding: 1.5em 0.8em;
    p {
      &.totalBudget {
        font-size: 1em;
      }
      &.totalEmails {
        font-size: 1em;
      }
    }
  }

}

.tableResult {
    display: grid;
    grid-template-columns: 32.5% 17.5% 25% 25%;
    grid-template-rows: 5.875em;
  
    .rowAlign {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        align-self: normal;
        .firstBarLoading,
        .secondBarLoading {
          width: 7.5em;
          height: 1em;
          border-radius: 0.25em;
          background-color: $lighterSaphir;
          animation-duration: 1s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-name: lazyLoadTableCustom;
          animation-timing-function: linear;
          animation-timing-function: linear;
          background: $lighterSaphir;
          background-size: 8em 1.4em;
          background-image: linear-gradient(
            to right,
            $lighterSaphir 0%,
            $ghostWhite 20%,
            $lighterSaphir 40%,
            $lighterSaphir 100%
          );
        }
      }
    > div {
      padding: 0.5em 0.8em;
  
      &:nth-child(1) {
        grid-column: 3;
      }
      &:nth-child(1) {
        border-bottom-left-radius: 0.3em;
        border-left: 1px solid $darkerSaphir;
        border-bottom: 1px solid $darkerSaphir;
      }
      &:nth-child(2n) {
        border-bottom-right-radius: 0.3em;
        border-right: 1px solid $darkerSaphir;
        border-bottom: 1px solid $darkerSaphir;
        border-left: 1px solid $lighterGrey;
        justify-content: flex-end;
      }
      span {
        font-size: 0.875em;
        font-style: italic;
      }
      p {
        color: $black;
        font-family: $font-sourceSansPro;
        font-weight: 500;
        
        &.totalEmails {
          font-size: 1.375em;
          font-weight: 700;
        }
        &.fraisProd {
            font-size: 1em;
        }
        &.totalBudget {
          font-size: 1.375em;
          font-weight: 700;
        }
      }
    }
    .wrapperTotalBudget {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    @keyframes lazyLoadTableCustom {
      0% {
        background-position: -15em 0;
      }
  
      100% {
        background-position: 15em 0;
      }
    }
  }
