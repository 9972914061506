.listsPage {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.listsContentWrapper {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0em 2.438em 0em 2.438em;

  .listsHeaderWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 40%;
    width: 100%;
    .listsHeader {
      display: flex;
      margin-top: 3em;
      margin-bottom: 1.4em;
      height: 30%;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .listsHeaderTitle {
        h2 {
          font-family: $font-sourceSansPro;
          color: $black;
          font-weight: 700;
          font-size: 2em;
        }
      }
      .newListButton {
        span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          div {
            margin-right: 0.313em;
          }
          .newListText {
            width: 100%;
          }
        }
      }
    }
    label {
      width: 15.125em;
      font-size: 1em;
    }
    .listsTabs {
      font-size: 1em;
      margin-top: 2.813em;
    }
  }
  .listsBodyWrapper {
    width: 100%;
    margin-top: 3.25em;
    margin-bottom: 3.75em;
    cursor: pointer;
    table {
      width: 100%;
      border-collapse: initial;
    }
    .countLoading {
      margin: auto;
      height: fit-content;
      width: 18em;
      text-align: center;
      p {
        font-family: $font-sourceSansPro;
        font-size: 1.125em;
        color: $lightSaphir;
        font-weight: bold;
        margin-top: 1.125em;
      }

      img {
        height: 100%;
        width: 100%;
      }
    }
    thead tr {
      font-weight: bold;
      color: $black;
      font-size: 1em;
      text-align: left;
      padding-left: 1.5em;
    }
    .count {
      position: relative;
      &:hover {
        border-radius: 8px;
        box-shadow: 0em 0.25em 1em rgba(0, 0, 0, 0.16);
        border: transparent;
      }
    }
    th {
      padding-bottom: 1.25em;
      padding-left: 1.5em;
      font-size: 1em;
    }
    td {
      padding-left: 1.5em;
      padding-top: 1.25em;
      padding-bottom: 1.188em;
      border-top: 1px solid $lighterGreyBis;
    }
    .tdWrapper {
      display: flex;

      &.centerContent {
        align-items: center;
      }

      &.compensateHeight {
        min-height: 4.875em;
      }

      .composedCell {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
    .cellText {
      width: 100%;
      font-weight: 400;
      font-size: 1em;

      &.locality {
        position: relative;
        z-index: 1;
      }

      .clientName {
        font-weight: 400;
        font-size: 1em;
        font-family: $font-sourceSansPro;

        span {
          color: $lightGrey;
        }
      }

      .externalLinkContainer {
        width: fit-content;
      }

      .imageContainer {
        position: absolute;
        z-index: 5;
        max-width: 300px;
      }
    }
    .totalBudget {
      text-align: right;
      padding-right: 1.5em;
    }
    .divider {
      border-right: 1px solid $lighterGrey;
      height: 1.875em;
    }
    .bigDivider {
      border-right: 1px solid $lighterGrey;
      height: 4.875em;
    }
    .threePointsVertical {
      -ms-transform: rotate(90deg); /* IE 9 */
      transform: rotate(90deg);
      color: $lightGrey;
    }
    .btnVoir {
      Button {
        position: relative;
        bottom: 6px;
        font-weight: 600;
      }
    }
    .circle {
      display: inline-block;
      width: 2em;
      height: 2em;
      border-radius: 50%;
      color: $white;
      background-color: $darkerSaphir;
      text-align: center;
      position: relative;
      bottom: 5px;
      .initial {
        display: block;
        padding-top: 0.313em;
      }
    }
    .proposalLink {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      text-decoration: none;
      color: $black;
    }
  }
  .loader {
    display: flex;
    margin-bottom: 2em;
    color: $lightGrey;
    font-weight: normal;
  }
  .listsFooterWrapper {
    padding-bottom: 4em;
    a[data-testid='gsl.uilib.Paging.nextButton'],
    a[data-testid='gsl.uilib.Paging.prevButton'] {
      background-color: $saphir;
      border: none;
    }
  }
  .modal {
    width: 11.125em;
    font-size: 1em;
    position: absolute;
    box-shadow: 0 0.375em 1.5em 0 #00000029;
    border-radius: 0.25em;
    background-color: $white;
    ul li:first-child {
      margin-top: 0.5em;
    }
    ul li:last-child {
      margin-bottom: 0.5em;
    }
    li {
      padding: 0.563em 1em 0.563em 1em;
      &:hover {
        background-color: $greyAutocomplete;
      }
      div {
        margin-right: 0.5em;
      }
    }
  }
  .backgroundPopin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    .unavailable {
      pointer-events: none;
      opacity: 0.2;
    }
  }
}
