.tableCountsEmail {
    margin-top: 2.875em;

    .tableCountsTitle {
      margin-bottom: 1.5em;
      p {
        font-size: 1.125em;
        color: $black;
        font-family: $font-sourceSansPro;
        font-weight: 700;
      }
    }
    .wrapperTable {
      width: 100%;
      @import "../../../components/Tables/ContactsByBroadcastTable/TitleTable/titleTable.scss";
      @import "../../../components/Tables/ContactsByBroadcastTable/ResultTable/resultTable.scss";
    }
    div[data-testid="gsl.uilib.Banner.Close"] {
      visibility: hidden;
    }
  }