.wrapperOverlay {
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  z-index: $z-overlay;

  .backgroundOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
  }

  .contentOverlay {
    position: absolute;
    background: $white;
    width: 42.5em;
    left: calc(50% + 8em);
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 2em;
    padding-bottom: 1em;
    box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.16);
    border-radius: 0.3em;
  }
}
