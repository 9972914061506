.wrapperListBroadcastMedium {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-dropdown;

  .backgroundListBroadcastMedium {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .wrapperBroadcastMediumEmail {
    position: absolute;
    left: 0;
    z-index: 1;
    margin-top: .25em;
    padding: 0.813em;
    background: $white;
    box-shadow: 0px 2px 8px 0px #00000029;
    border-radius: 0.25em;
    font-weight: 400;
    font-family: $font-sourceSansPro;

    &.isEmpty {
      min-width: 22em;
    }

    .infoBroadcastMedium {
        font-size: 0.875em;
        color: $grey;
    }

    .broadcastMediumEmpty {
        font-size: 1em;
    }

    .listBroadcastMedium {
        margin-top: 1.125em;
        font-size: 1em;
        color: $black;
        .title {
            margin-bottom: 0.438em;
        }
        .wrapLogoBroadcastMedium {
            display: flex;
            margin-bottom: 0.938em;
        }
        .errorBroadcastMedium {
            font-size: 0.875em;
            color: $persianRed;
            margin-top: 0.375em;
            margin-bottom: 0.813em;
        }
    }

    .showErrorBroadcastMedium {
        border: 1px solid #C52A2A;
    }
  }
}
