Button.closeOverlay {
  border: 0px;
  background-color: $white;
  cursor: pointer;
  position: absolute;
  top: 1em;
  right: 1em;
}

.overlayFirstConnection {
  .wrapperOverlayFirstConnection {
    text-align: justify;
    h2 {
      text-align: center;
      font-size: 1.5em;
      font-family: $font-sourceSansPro;
      font-weight: 700;
      padding-bottom: 0.6em;
    }
    > p {
      text-align: center;
      margin-top: 1em;
    }
    .wrapperSelectJob {
      margin-top: 1.25em;
      padding-left: 2em;
      padding-right: 2em;
    }
    .wrapperSelectMarket {
      margin-top: 1.25em;
    }
    .wrapperbuttonValidate {
      text-align: center;
      margin-top: 2.25em;
    }


    .overlayHeader {
      padding-left: 2em;
      padding-right: 2em;
      display: flex;
      margin-top: 40px;
      margin-bottom: 20px;

      .circle , .tdWrapper {
        height: 48px;
        width: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        .initial {
          padding: 0;
        }
      }

      .overlayInfosUser {
        font-size: 18px;
        font-weight: 700;
        margin-left: 20px;
        .overlayInfosUserEmail {
          font-size: 16px;
        font-weight: 400;
        }
      }
    }
  }
}
