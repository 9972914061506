.radiusExtendLocality {
  margin: 6px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;

  .space-between {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
}
