.overlayRcCLient {
  .closeOverlay {
    cursor: pointer;
    position: absolute;
    top: 1em;
    right: 1em;
  }
  .wrapperOverlayRcClient {
    padding-left: 2em;
    padding-right: 2em;
    text-align: justify;
    h2 {
      text-align: center;
      font-size: 2em;
      font-family: $font-sourceSansPro;
      font-weight: 700;
    }
    > p {
      text-align: center;
      margin-top: 1em;
      margin-bottom: 1.5em;
    }
    .wrapperbuttonValidate {
      text-align: center;
      margin-top: 1.25em;
    }
  }
}
