.codeMaf {
  margin-left: 1em;
  height: 2.5em;
  display: flex;

  .contentCodeMaf {
    display: flex;
    align-items: center;
    color: $lightGrey;
    padding: 0.75em;
    border-top-left-radius: 0.3em;
    border-bottom-left-radius: 0.3em;
    border: 1px solid $lightGrey;
    border-right: none;
  }

  @media print {
    .contentCodeMaf {
      border-right: 1px solid $lightGrey;
      border-top-right-radius: 0.3em;
      border-bottom-right-radius: 0.3em;
    }
  }

  .copyPast {
    position: relative;
    width: 2.5em;
    border-top-right-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
    background-color: $greyAutocomplete;
    border: 1px solid $lightGrey;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $lightGrey;
    cursor: pointer;
    overflow: hidden;
    transition: background-color 0.2s;

    &:hover {
      background-color: $lighterGrey;
    }

    .wrapperIcon {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
    }

    //Animation Icon Validate
    .iconValidate-enter {
      transform: translateY(-100%);
    }
    .iconValidate-enter-active {
      transform: translateY(0%);
      transition: transform 300ms;
    }
    .iconValidate-exit {
      transform: translateY(0%);
    }
    .iconValidate-exit-active {
      transform: translateY(100%);
      transition: transform 300ms;
    }

    //Animation Icon Copy
    .iconCopy-enter {
      transform: translateY(-100%);
    }
    .iconCopy-enter-active {
      transform: translateY(0%);
      transition: transform 300ms;
    }
    .iconCopy-exit {
      transform: translateY(0%);
    }
    .iconCopy-exit-active {
      transform: translateY(100%);
      transition: transform 300ms;
    }
  }

  @media print {
    .copyPast {
      visibility: hidden;
    }
  }
}
