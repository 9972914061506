@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
  @page {
    size: 343mm 450mm;
    max-height:100%;
    max-width:100%;
    margin:0;
  }
}