$base-path-font: "./../../assets/fonts/";
$base-font-fontello: $base-path-font + "fontello/";
$base-font-source-sans-pro: $base-path-font + "sourceSansPro/";

//-------------------------------------------------------------------
// Source Sans Pro

$font-sourceSansPro: 'Source Sans Pro';

$fontpath: $base-font-source-sans-pro+"Black/SourceSansPro-Black";
@font-face {
  font-family: $font-sourceSansPro;
  src: url($fontpath+'.eot');
  src: url($fontpath+'.eot?#iefix') format('embedded-opentype'),
  url($fontpath+'.woff') format('woff'),
  url($fontpath+'.ttf') format('truetype'),
  url($fontpath+'.svg') format('svg');
  font-weight: 900;
}

$fontpath: $base-font-source-sans-pro+"Bold/SourceSansPro-Bold";
@font-face {
  font-family: $font-sourceSansPro;
  src: url($fontpath+'.eot');
  src: url($fontpath+'.eot?#iefix') format('embedded-opentype'),
  url($fontpath+'.woff') format('woff'),
  url($fontpath+'.ttf') format('truetype'),
  url($fontpath+'.svg') format('svg');
  font-weight: 700;
}

$fontpath: $base-font-source-sans-pro+"SemiBold/SourceSansPro-SemiBold";
@font-face {
  font-family: $font-sourceSansPro;
  src: url($fontpath+'.eot');
  src: url($fontpath+'.eot?#iefix') format('embedded-opentype'),
  url($fontpath+'.woff') format('woff'),
  url($fontpath+'.ttf') format('truetype'),
  url($fontpath+'.svg') format('svg');
  font-weight: 600;
}

$fontpath: $base-font-source-sans-pro+"Regular/SourceSansPro-Regular";
@font-face {
  font-family: $font-sourceSansPro;
  src: url($fontpath+'.eot');
  src: url($fontpath+'.eot?#iefix') format('embedded-opentype'),
  url($fontpath+'.woff') format('woff'),
  url($fontpath+'.ttf') format('truetype'),
  url($fontpath+'.svg') format('svg');
  font-weight: 400;
}

//--------------------------------------------------------------------
// Fontello

@font-face {
  font-family: "fontello";
  src: url($base-font-fontello + "fontello.eot?44201586");
  src: url($base-font-fontello + "fontello.eot?44201586#iefix") format("embedded-opentype"),
    url($base-font-fontello + "fontello.woff2?44201586") format("woff2"),
    url($base-font-fontello + "fontello.woff?44201586") format("woff"),
    url($base-font-fontello + "fontello.ttf?44201586") format("truetype"),
    url($base-font-fontello + "fontello.svg?44201586#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-account:before { content: '\e800'; } /* '' */
.icon-home:before { content: '\e802'; } /* '' */
.icon-chevron:before { content: '\e803'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */

/*
     Animation example, for spinners
  */
.animate-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
