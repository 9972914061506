.contentOverlayDeleteCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .binRegular {
    color: $candy;
    margin-bottom: 10px;
  }
  p {
    font-size: 18px;
  }
}
hr {
  border: none;
  height: 1px;
  background-color: $lighterGrey;
}
.buttonsDeleteOverlay {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-left: 2em;
  padding-right: 2em;
}
