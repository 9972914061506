.productCard {
  position: relative;
  padding: 0.5em;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  height: 100%;
  border-radius: 0.2em;

  .cardCover {
    width: 100%;
    padding-top: 38.6%;
    border-radius: 0.2em;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .wrapperTags {
    margin-top: 0.5em;
    margin-bottom: 0.75em;

    .contentTag {
      display: inline-block;
      margin-top: 0.5em;
    }
  }
  .wrapperDescription {
    padding: 0 0.375em;
    h3 {
      font-size: 1.125em;
      font-family: $font-sourceSansPro;
      font-weight: 700;
    }
    .description {
      margin-top: 0.5em;
      margin-bottom: 5em;

      p {
        font-size: 1em;
        font-family: $font-sourceSansPro;
      }
    }
  }

  .wrapperCTA {
    min-height: 2.2em;
    width: calc(100% - 2em);
    align-items: center;
    display: flex;
    position: absolute;
    left: 1em;
    bottom: 0.95em;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;

    .moreInformations {
      padding: .2em 0;
      margin-right: 1em;
      text-align: right;
      color: $black;

      a {
        &:link,
        &:visited,
        &:hover,
        &:active {
          color: $black;
        }
      }
    }
  }
}
