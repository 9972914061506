//----------------------------------------------------------------------
// Primary & Secondary Color

$lighterCandy: #fad5de;
$lightCandy: #f88fa7;
$candy: #ef1b4c;
$darkCandy: #ce0030;
$darkerCandy: #9d0024;
$persianRed: #c52a2a;

$lighterSaphir: #e8e9fd;
$lightSaphir: #a9b1ed;
$saphir: #4d54c5;
$darkSaphir: #242ba0;
$darkerSaphir: #29347d;

//----------------------------------------------------------------------
// Gradient Color

$candyGradientStart: #6f2acf;
$candyGradientStop: #e00034;

$saphirGradientStart: #6f2acf;
$saphirGradientStop: #09a3e9;

//----------------------------------------------------------------------
// Complementary Color
$lighterOrange: #fdf2e4;
$gold: #f5a623;
$lightOrange: #ffb75a;
$back: #00819e;
$lightGrass: #e4f7f5;
$normalGrass: #008577;
$hoverMenu: #1b2672;
$hoverUserAccountInformations: #e8e9fd;
$black: #2b2b2b;
$lighterGreyBis: #bdc1c1;
$lighterGrey: #eaeaea;
$lightGrey: #929292;
$grey: #707070;
$greyTable: #cccccc;
$greyAutocomplete: #f8f8f8;
$white: #ffffff;
$ghostWhite: #f7f8ff;
$whiteSmoke: #fbeeee;
$greySelect: #cccccc;
$error: #e00034;
$successBackground: #eef5f2;
$success: #227c59;
$darkGreen: #00584f;
$lavenderWeb : #E7E9FD;

//----------------------------------------------------------------------
// z-index

$z-form: 10;
$z-dropdown: 20;
$z-header: 30;
$z-overlay: 40;
$z-tooltip: 50;
$z-max: 1000;