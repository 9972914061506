.wrapperTitleTable.wrapperProposalTitleTable {
  grid-template-columns: 32.6% 28.5% 32% 25%;
    .blue {
      background-color: $darkSaphir;
      color: $white;
    }
    > div {
      font-family: $font-sourceSansPro;
      font-weight: 700;
      padding: 0.5em 0.8em;
      border-top: 1px solid $lighterGrey;
      border-left: 1px solid $lighterGrey;
  
      &:nth-child(3n) {
        border-left: 1px solid $white;
        border-top: 1px solid $white;
      }
    }
}
.wrapperTitleTable {
    display: grid;
    grid-template-columns: 32.5% 17.5% 25% 25%;
  
    .blue {
      background-color: $darkSaphir;
      color: $white;
    }
    > div {
      font-family: $font-sourceSansPro;
      font-weight: 700;
      padding: 0.5em 0.8em;
      border-top: 1px solid $lighterGrey;
      border-left: 1px solid $lighterGrey;
  
      &:first-child {
        border-top-left-radius: 0.3em;
      }
      &:last-child {
        border-top-right-radius: 0.3em;
      }
      &:nth-child(4n) {
        border-right: 1px solid $darkSaphir;
        border-top: 1px solid $darkSaphir;
      }
      &:nth-child(3n) {
        border-left: 1px solid $darkSaphir;
        border-top: 1px solid $darkSaphir;
      }
    }
  }
  